import React from "react";
import { useNavigate } from "react-router-dom";
import "../components/NotFoundPage/NotFoundPage.css";

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/");
  };

  return (
    <div className="notfound-page-container">
      <h1 className="notfound-title">Oops! Halaman Tidak Ditemukan</h1>
      <p className="notfound-message">
        Sepertinya halaman yang kamu cari tidak ada. Mungkin tersembunyi di peta
        harta karun?
      </p>
      <img
        src="/taro-banner.png"
        alt="Ilustrasi halaman tidak ditemukan"
        className="notfound-image"
        aria-label="Karakter atau harta yang hilang"
      />
      <div className="notfound-action-buttons">
        <button
          onClick={handleGoBack}
          className="notfound-back-button"
          aria-label="Kembali ke Beranda"
        >
          Kembali ke Beranda
        </button>
      </div>
      <p className="notfound-contact-info">
        Masih tersesat? Hubungi kami di{" "}
        <a href="mailto:support@example.com" aria-label="Email support">
          @tarosnack.id{" "}
        </a>
        atau telepon +62 822-6055-8192 untuk bantuan.
      </p>
    </div>
  );
};

export default NotFoundPage;
