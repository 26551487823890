import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { FormProvider } from "./contexts/FormContext"; // Import FormContext
import NotFoundPage from "./pages/NotFoundPage";

const LandingPage = lazy(() => import("./pages/LandingPage"));
const FormPage = lazy(() => import("./pages/FormPage"));
const TreasurePage = lazy(() => import("./pages/TreasurePage"));
const TermsPage = lazy(() => import("./pages/TermsPage"));

function App() {
  return (
    <FormProvider>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/form" element={<FormPage />} />
            <Route path="/treasure" element={<TreasurePage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
      </Router>
    </FormProvider>
  );
}

export default App;
